import PropTypes from "prop-types"
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"

import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logoLightSvg from "../../assets/images/icons.png"
//i18n
import { withTranslation } from "react-i18next"
import API from "../../apis";
// Redux Store

const Header = props => {
  const [annee, setAnnee] = useState(""); // Ajout d'un state pour l'année
  useEffect(() => {
    // Fonction à exécuter au chargement du composant
    upload(); // Récupérer la variable "annee" ici
  }, []);

  function toggleFullscreen() {
    if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }

  const upload = async (row) => {
    if (localStorage.getItem("anneeScolaire")) {
      var anneeScolaire = JSON.parse(localStorage.getItem("anneeScolaire"));
      // Mise à jour de l'année
      const resD = await API.post("annee/get_by_id",{id:anneeScolaire}).then(resD => {
        setAnnee(resD.data.Annee.annee_scolaire);
      })
    }
  };
  return (
      <React.Fragment>
        <header id="page-topbar">
          <div   className="navbar-header">

            <div className="d-flex">
              <div className="navbar-brand-box d-lg-none d-md-block">
                <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" width="100%" />
                </span>
                </Link>
              </div>
              <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
              >
                <i  className="fa fa-fw fa-bars" />
              </button>
              <form className="app-search d-none d-lg-block"></form>
            </div>
            <div className="d-flex">
              <label style={{"font-weight":"600","font-size":"15px"}}>ESPACE ETUDIANT - ANNÉE UNIVERSITAIRE : {annee} </label>
            </div>
            <div className="d-flex">
              {/* <LanguageDropdown /> */}
              <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                    type="button"
                    onClick={() => {
                      toggleFullscreen()
                    }}
                    className="btn header-item noti-icon "
                    data-toggle="fullscreen"
                >
                  <i   className="bx bx-fullscreen" />
                </button>
              </div>
              {/* <NotificationDropdown /> */}
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

export default (withTranslation()(Header))





// import PropTypes from "prop-types"
// import React, { useState } from "react"
// import { Link } from "react-router-dom"
//
// // Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
//
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
// import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
// import logoLightSvg from "../../assets/images/icons.png"
// //i18n
// import { withTranslation } from "react-i18next"
// // Redux Store
//
// const Header = props => {
//
//   function toggleFullscreen() {
//     if (
//       !document.fullscreenElement &&
//       /* alternative standard method */ !document.mozFullScreenElement &&
//       !document.webkitFullscreenElement
//     ) {
//       // current working methods
//       if (document.documentElement.requestFullscreen) {
//         document.documentElement.requestFullscreen()
//       } else if (document.documentElement.mozRequestFullScreen) {
//         document.documentElement.mozRequestFullScreen()
//       } else if (document.documentElement.webkitRequestFullscreen) {
//         document.documentElement.webkitRequestFullscreen(
//           Element.ALLOW_KEYBOARD_INPUT
//         )
//       }
//     } else {
//       if (document.cancelFullScreen) {
//         document.cancelFullScreen()
//       } else if (document.mozCancelFullScreen) {
//         document.mozCancelFullScreen()
//       } else if (document.webkitCancelFullScreen) {
//         document.webkitCancelFullScreen()
//       }
//     }
//   }
//
//   function tToggle() {
//     var body = document.body
//     if (window.screen.width <= 998) {
//       body.classList.toggle("sidebar-enable")
//     } else {
//       body.classList.toggle("vertical-collpsed")
//       body.classList.toggle("sidebar-enable")
//     }
//   }
//
//   return (
//     <React.Fragment>
//       <header id="page-topbar">
//         <div style={{ backgroundColor: "#2a3042" }} className="navbar-header">
//           <div className="d-flex">
//             <div className="navbar-brand-box d-lg-none d-md-block">
//               <Link to="/dashboard" className="logo logo-light">
//                 <span className="logo-sm">
//                   <img src={logoLightSvg} alt="" width="100%" />
//                 </span>
//               </Link>
//             </div>
//             <button
//               type="button"
//               onClick={() => {
//                 tToggle()
//               }}
//               className="btn btn-sm px-3 font-size-16 header-item "
//               id="vertical-menu-btn"
//             >
//               <i style={{ color: "white" }} className="fa fa-fw fa-bars" />
//             </button>
//             <form className="app-search d-none d-lg-block"></form>
//           </div>
//           <div className="d-flex">
//           {/* <LanguageDropdown /> */}
//             <div className="dropdown d-none d-lg-inline-block ms-1">
//               <button
//                 type="button"
//                 onClick={() => {
//                   toggleFullscreen()
//                 }}
//                 className="btn header-item noti-icon "
//                 data-toggle="fullscreen"
//               >
//                 <i style={{ color: "white" }} className="bx bx-fullscreen" />
//               </button>
//             </div>
//             {/* <NotificationDropdown /> */}
//             <ProfileMenu />
//           </div>
//         </div>
//       </header>
//     </React.Fragment>
//   )
// }
//
// Header.propTypes = {
//   changeSidebarType: PropTypes.func,
//   leftMenu: PropTypes.any,
//   t: PropTypes.any,
//   toggleLeftmenu: PropTypes.func,
// }
//
// export default (withTranslation()(Header))
