import React, { useEffect, useState } from "react"
import APIS from "../../apis"
import logo from "../../assets/images/ISLG.jpg"
import user1 from "../../assets/images/users/userImage.png"

const ImpressionInscription = () => {
    const [nomPrenom, setNomPrenom] = useState("")
    const [nomPrenomAr, setNomPrenomAr] = useState("")
    const [prenomPere, setPrenomPere] = useState("")
    const [prenomPereAr, setPrenomPereAr] = useState("")
    const [gender, setGender] = useState(1)
    const [date, setDate] = useState("")
    const [gouvernoratNaiss, setGouvernoratNaiss] = useState("")
    const [gouvernoratNaissAr, setGouvernoratNaissAr] = useState("")
    const [selectNationnalite, setSelectNationnalite] = useState("")
    const [numero, setNumero] = useState("")
    const [etatCivil, setEtatCivil] = useState(1)
    const [rue, setRue] = useState("")
    const [codePostal, setCodePostal] = useState("")
    const [gouvernorat, setGouvernorat] = useState("")
    const [delegation, setDelegation] = useState("")
    const [tel, setTel] = useState("")
    const [email, setEmail] = useState("")
    const [anneeBac, setAnneeBac] = useState("")
    const [section, setSection] = useState("")
    const [mention, setMention] = useState("")
    const [session, setSession] = useState("")
    const [payObtention, setPayObtention] = useState("")
    const [premiereIns, setPremiereIns] = useState("")
    const [selectCycle, setSelectCycle] = useState("")
    const [selectNiveau, setSelectNiveau] = useState("")
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [etudiantId, setEtudiantId] = useState("")
    const [etat, setEtat] = useState("")
    const [moyen, setMoyen] = useState("");
    const [selectAnnee, setSelectAnnee] = useState("");
    const [annee, setAnnee] = useState("");
    const [nomPrenomMere, setNomPrenomMere] = useState("");
    const [nomPrenomMereAr, setNomPrenomMereAr] = useState("");
    useEffect(async () => {

        const resAnnee = await APIS.get("annee/select").then(resAnnee => {
            setSelectAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].value)
            setAnnee(resAnnee.data.Annee[0].CurrentAnnee[0].label)
            localStorage.setItem("annee", selectAnnee)
        })

    })
    useEffect(async () => {
        var annee_id = localStorage.getItem("annee")
        var url = window.location.href
        var array = url.split("=")
        var cin = array[1]
        const res = await APIS.post("renseignement/get_by_cin", {
            cin: cin,
            annee_id:annee_id
        }).then(res => {
            if (res.data.status === 200) {
                const resC = APIS.post("check_profile_image", {
                    type: "etudiants",
                    id: res.data.Renseignement.id,
                    annee_id:annee_id
                }).then(resC => {
                    setEtat(resC.data.error)
                })
            }
            setNomPrenomMere(res.data.Renseignement.prenonMere_fr + ' ' + res.data.Renseignement.nonMere_fr)
            setNomPrenomMereAr(res.data.Renseignement.prenonMere_fr + ' ' + res.data.Renseignement.nonMere_fr)
            setEtudiantId(res.data.Renseignement.id)
            setNomPrenom(res.data.Renseignement.prenom_fr +' '+res.data.Renseignement.nom_fr)
            setNomPrenomAr(res.data.Renseignement.prenom_ar +' '+res.data.Renseignement.nom_ar )
            setPrenomPere(res.data.Renseignement.prenonPere_fr+ ' '+ res.data.Renseignement.nonPere_fr)
            setPrenomPereAr(res.data.Renseignement.prenonPere_ar + ' '+res.data.Renseignement.nonPere_ar)
            setGender(res.data.Renseignement.sexe)
            setDate(res.data.Renseignement.dateNaissance)
            setGouvernoratNaiss(res.data.Renseignement.lieuNaissance_fr)
            setGouvernoratNaissAr(res.data.Renseignement.lieuNaissance_ar)
            setSelectNationnalite(res.data.Renseignement.nationalite.label)
            setNumero(cin)
            setEtatCivil(res.data.Renseignement.etatCivil)
            setRue(res.data.Renseignement.rue)
            setCodePostal(res.data.Renseignement.codePostal)
            setGouvernorat(res.data.Renseignement.gouvernorat_fr.label)
            setDelegation(res.data.Renseignement.accreditation_fr)
            setTel(res.data.Renseignement.telephone)
            setEmail(res.data.Renseignement.email)
            setSection(res.data.Renseignement.section_fr.label)
            setMention(res.data.Renseignement.mention_fr.label)
            setSession(res.data.Renseignement.session_fr.label)
            setAnneeBac(res.data.Renseignement.anneBac)
            setPayObtention(res.data.Renseignement.paysObtention)
            setPremiereIns(res.data.Renseignement.premereInscription)
            setSelectNiveau(res.data.Renseignement.niveau.label)
            setSelectCycle(res.data.Renseignement.cycle.label)
            setSelectSpecialite(res.data.Renseignement.specialite.label)
            setMoyen(res.data.Renseignement.moyen)
        })
    }, [])

    const print = () => {
        window.print()
    }

    return (
        <div className="container">
            <table style={{ width: "100%" }}>
                <tbody>
                <tr>
                    <td
                        style={{
                            verticalAlign: "middle",
                        }}
                    >
                        <img src={logo} width={100}/>
                    </td>
                    <td colSpan={2}/>
                    <td
                        style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                        }}
                        nowrap
                    >
                        <div
                            className="col"
                            style={{fontSize: "12px", textAlign: "center"}}
                        >
                            الجمهورية التونسية
                            <div
                                className="col"
                                style={{fontSize: "12px", textAlign: "center"}}
                            >
                                وزارة التعليم العالي والبحث العلمي
                            </div>
                            <div
                                className="col"
                                style={{
                                    fontSize: "12px",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    paddingTop: "10px",
                                }}
                            >
                                جامعة قابس
                            </div>
                            <div
                                className="col"
                                style={{
                                    textAlign: "center",
                                    marginRight: 0,
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                }}
                            >
                                اﻟﻤﻌﻬﺪ اﻟﻌﺎﻟﻲ ﻟﻠﻐﺎت ﺑﻘﺎﺑﺲ{" "}
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={4} valign="middel">
                        <div
                            style={{
                                height: "1px",
                                width: "100%",
                                borderBottom: "solid 1px #eee",
                                marginBottom: "15px",
                            }}
                        />
                    </td>
                </tr>
                <tr>
                    <td
                        colSpan={4}
                        style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                        }}
                    >
                        <center>
                            <h4
                                style={{
                                    padding: "0px",
                                    margin: "0px",
                                    fontSize: "17px",
                                    fontFamily: '"Tahoma"',
                                }}
                            >
                                Fiche des Renseignements - بطاقة بيانات
                            </h4>
                        </center>
                        <br/>
                        <center>
                            <h4
                                style={{
                                    padding: "0px",
                                    margin: "0px",
                                    fontSize: "17px",
                                    fontFamily: '"Tahoma"',
                                }}
                            >
                                Années universitaire {annee}
                            </h4>
                        </center>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style={{width: "130px"}}>
                            {etat != 0 ? (
                                <img
                                    style={{width: "100%"}}
                                    src={
                                        "https://islgb.scolarite.backcresus-institut.ovh/api/assetsmanager/renseignement/" + etudiantId + "/" + selectAnnee
                                    }
                                />
                            ) : (
                                <img style={{width: "100%"}} src={user1}/>
                            )}
                        </div>
                        <br/>
                        <br/>
                    </td>
                    <td/>
                    <td dir="rtl"/>
                    <td dir="rtl"/>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <h4
                            style={{
                                padding: "0px",
                                margin: "0px",
                                fontSize: "14px",
                                fontFamily: '"Tahoma"',
                                color: "#0e8395",
                                fontWeight: 600,
                            }}
                        >
                            Informations générales
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Nom et Prénom</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {nomPrenom}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        {nomPrenomAr}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        <b>الإسم و اللقب</b>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Prénom Père</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {prenomPere}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        {prenomPereAr}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        <b>الإسم و اللقب الاب</b>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Nom et Prénom Mère</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {nomPrenomMere}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        {nomPrenomMereAr}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        <b>الإسم و اللقب الأم</b>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Sexe</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {gender == 1 ? "Masculin" : "Féminin"}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Date de naissance</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {date}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Gouvernorat de naissance</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {gouvernoratNaiss}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        {gouvernoratNaissAr}
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                        dir="rtl"
                    >
                        <b>الولاية</b>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Nationalité</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {selectNationnalite}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>N° CIN ou N° Passeport pour les étrangers</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {numero}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Etat Civil</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {etatCivil == 1
                            ? "Célibataire"
                            : etatCivil == 2
                                ? "Marié(e)"
                                : "Divorcé(e)"}
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <br/>
                        <br/>
                        <h4
                            style={{
                                padding: "0px",
                                margin: "0px",
                                fontSize: "14px",
                                fontFamily: '"Tahoma"',
                                color: "#0e8395",
                                fontWeight: 600,
                            }}
                        >
                            Adresse
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Rue</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {rue}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Code postal</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {" "}
                        {codePostal}{" "}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Gouvernorat</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {" "}
                        {gouvernorat}{" "}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Délégation</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {delegation}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Téléphone</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {tel}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>E-mail</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {email}
                    </td>
                </tr>
                <tr>
                    <td colSpan={4}>
                        <br/>
                        <br/>
                        <h4
                            style={{
                                padding: "0px",
                                margin: "0px",
                                fontSize: "14px",
                                fontFamily: '"Tahoma"',
                                color: "#0e8395",
                                fontWeight: 600,
                            }}
                        >
                            Baccalauréat
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Année du bac</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {anneeBac}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Section</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {section}
                    </td>
                </tr>
                {/*<tr>*/}
                {/*    <td*/}
                {/*        style={{*/}
                {/*            fontSize: "12px",*/}
                {/*            fontFamily: '"Tahoma"',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <b>Moyen Bac</b>*/}
                {/*    </td>*/}
                {/*    <td*/}
                {/*        style={{*/}
                {/*            fontSize: "12px",*/}
                {/*            fontFamily: '"Tahoma"',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {" "}*/}
                {/*        {moyen}{" "}*/}
                {/*    </td>*/}
                {/*</tr>*/}
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Mention</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {mention}{" "}
                    </td>
                </tr>

                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Session</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {" "}
                        {session}{" "}
                    </td>
                </tr>

                {/*<tr>*/}
                {/*    <td*/}
                {/*        style={{*/}
                {/*            fontSize: "12px",*/}
                {/*            fontFamily: '"Tahoma"',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <b>{"Pays d'obtention"}</b>*/}
                {/*    </td>*/}
                {/*    <td*/}
                {/*        style={{*/}
                {/*            fontSize: "12px",*/}
                {/*            fontFamily: '"Tahoma"',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {payObtention}{" "}*/}
                {/*    </td>*/}
                {/*</tr>*/}
                {/*<tr>*/}
                {/*    <td*/}
                {/*        style={{*/}
                {/*            fontSize: "12px",*/}
                {/*            fontFamily: '"Tahoma"',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        <b>{"1ère inscription à L'établissement"}</b>*/}
                {/*    </td>*/}
                {/*    <td*/}
                {/*        style={{*/}
                {/*            fontSize: "12px",*/}
                {/*            fontFamily: '"Tahoma"',*/}
                {/*        }}*/}
                {/*    >*/}
                {/*        {premiereIns}{" "}*/}
                {/*    </td>*/}
                {/*</tr>*/}
                <tr>
                    <td colSpan={4}>
                        <br/>
                        <br/>
                        <h4
                            style={{
                                padding: "0px",
                                margin: "0px",
                                fontSize: "14px",
                                fontFamily: '"Tahoma"',
                                color: "#0e8395",
                                fontWeight: 600,
                            }}
                        >
                            Diplôme
                        </h4>
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Diplôme</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {selectCycle}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Niveau</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {" "}
                        {selectNiveau}{" "}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        <b>Spécialité</b>
                    </td>
                    <td
                        style={{
                            fontSize: "12px",
                            fontFamily: '"Tahoma"',
                        }}
                    >
                        {" "}
                        {selectSpecialite}{" "}
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2%",
                        marginBottom: "2%",
                    }}
                >
                    <i
                        id="print_me_not"
                        style={{fontSize: "30px", cursor: "pointer"}}
                        onClick={print}
                        className="fas fa-print"
                    ></i>
                </div>
            </div>
        </div>
    )
}

export default ImpressionInscription
